import './css/style.css'
import * as THREE from 'three'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls'
import * as lil from 'lil-gui'

class ColorGUIHelper {
    constructor(object, prop) {
        this.object = object;
        this.prop = prop;
    }
    get value() {
        return `#${this.object[this.prop].getHexString()}`;
    }
    set value(hexString) {
        this.object[this.prop].set(hexString);
    }
}

// Texture Loader
const loadingManager = new THREE.LoadingManager()
loadingManager.onStart = () =>
{
    console.log('Loading Textures')
}
loadingManager.onLoad = () =>
{
    console.log('Loading Complete.')
}
loadingManager.onProgress = () =>
{
    console.log('Please wait...')
}
loadingManager.onError = () =>
{
    console.log('Error!')
}
const textureLoader = new THREE.TextureLoader(loadingManager)

// Debug UI
const gui = new lil.GUI({closed: true, width: 400 })
gui.hide()
window.addEventListener('keydown', (event) => // Hide/Show debug panel by pressing 'h'
{
    if(event.key == 'h')
    {
        if(gui._hidden){ gui.show() } else { gui.hide() }
    }
})

const parameters = {
    fov: 55,
    shininess: 100,
    specular: new THREE.Color(0x1188ff),
    metalness: 0.9,
    roughness: 0
}

// Cursor
const cursor = {
    x: 0,
    y: 0
}
window.addEventListener('mousemove', (event) => 
{
    cursor.x = event.clientX / sizes.width - 0.5
    cursor.y = - (event.clientY / sizes.height - 0.5)
})

// Canvas
const canvas = document.querySelector('.webgl')

// Scene
const scene = new THREE.Scene()

// Fog
const fog = new THREE.Fog('#262837', 1, 17)
scene.fog = fog

// Textures 
const gradientTexture = textureLoader.load('/textures/gradients/5.jpg')
gradientTexture.minFilter = THREE.NearestFilter
gradientTexture.magFilter = THREE.NearestFilter
gradientTexture.generateMipmaps = false

const matcapTexture = textureLoader.load('/textures/matcaps/9.png')
const simpleShadow = textureLoader.load('/textures/simpleShadow.jpg')

// Door
const doorColorTexture = textureLoader.load('/textures/door/color.jpg')
const doorAlphaTexture = textureLoader.load('/textures/door/alpha.jpg')
const doorHeightTexture = textureLoader.load('/textures/door/height.jpg')
const doorNormalTexture = textureLoader.load('/textures/door/normal.jpg')
const doorAmbientOcclusionTexture = textureLoader.load('/textures/door/ambientOcclusion.jpg')
const doorMetalnessTexture = textureLoader.load('/textures/door/metalness.jpg')
const doorRoughnessTexture = textureLoader.load('/textures/door/roughness.jpg')
// End Door Textures

// Bricks
const bricksColorTexture = textureLoader.load('/textures/bricks/color.jpg')
const bricksAmbientOcclusionTexture = textureLoader.load('/textures/bricks/ambientOcclusion.jpg')
const bricksNormalTexture = textureLoader.load('/textures/bricks/normal.jpg')
const bricksRoughnessTexture = textureLoader.load('/textures/bricks/roughness.jpg')

// Graves
const graveColorTexture = textureLoader.load('/textures/grave/graveColorMap.jpg')

// Bushes
const bushColorTexture = textureLoader.load('/textures/bush/leaves.jpg')

// Grass
const grassColorTexture = textureLoader.load('/textures/grass/color.jpg')
const grassAmbientOcclusionTexture = textureLoader.load('/textures/grass/ambientOcclusion.jpg')
const grassNormalTexture = textureLoader.load('/textures/grass/normal.jpg')
const grassRoughnessTexture = textureLoader.load('/textures/grass/roughness.jpg')

grassColorTexture.repeat.set(9, 9)
grassAmbientOcclusionTexture.repeat.set(9, 9)
grassNormalTexture.repeat.set(9, 9)
grassRoughnessTexture.repeat.set(9, 9)
grassColorTexture.wrapS = THREE.RepeatWrapping
grassAmbientOcclusionTexture.wrapS = THREE.RepeatWrapping
grassNormalTexture.wrapS = THREE.RepeatWrapping
grassRoughnessTexture.wrapS = THREE.RepeatWrapping
grassColorTexture.wrapT = THREE.RepeatWrapping
grassAmbientOcclusionTexture.wrapT = THREE.RepeatWrapping
grassNormalTexture.wrapT = THREE.RepeatWrapping
grassRoughnessTexture.wrapT = THREE.RepeatWrapping


// Environment Map loader
const cubeTextureLoader = new THREE.CubeTextureLoader()
const environmentMapTexture = cubeTextureLoader.load([
    '/textures/environmentMaps/1/px.jpg',
    '/textures/environmentMaps/1/nx.jpg',
    '/textures/environmentMaps/1/py.jpg',
    '/textures/environmentMaps/1/ny.jpg',
    '/textures/environmentMaps/1/pz.jpg',
    '/textures/environmentMaps/1/nz.jpg'
])

// ============ Materials ============
// Wall Material
const wallMaterial = new THREE.MeshStandardMaterial({
    map: bricksColorTexture,
    aoMap: bricksAmbientOcclusionTexture,
    aoMapIntensity: 1,
    normalMap: bricksNormalTexture,
    roughnessMap: bricksRoughnessTexture,
})

// Grass Material
const grassMaterial = new THREE.MeshStandardMaterial({
    map: grassColorTexture,
    aoMap: grassAmbientOcclusionTexture,
    aoMapIntensity: 1,
    normalMap: grassNormalTexture,
    roughnessMap: grassRoughnessTexture,
})

// Bush Material
const bushMaterial = new THREE.MeshStandardMaterial({ map: bushColorTexture})
bushColorTexture.repeat.set(2,2)
bushColorTexture.wrapS = THREE.RepeatWrapping
bushColorTexture.wrapT = THREE.RepeatWrapping

// Roof Material
const roofMaterial = new THREE.MeshStandardMaterial({ color: '#b35f45'})

// Tombstone Material 
const graveMaterial = new THREE.MeshStandardMaterial({ map: graveColorTexture })

// Door Material
const doorMaterial = new THREE.MeshStandardMaterial({
    side: THREE.DoubleSide,
    map: doorColorTexture,
    aoMap: doorAmbientOcclusionTexture,
    aoMapIntensity: 1.25,
    normalMap: doorNormalTexture,
    alphaMap: doorAlphaTexture,
    transparent: true,
    metalnessMap: doorMetalnessTexture,
    roughnessMap: doorRoughnessTexture,
    metalness: 0.5,
    roughness: 0.8,
    envMap: environmentMapTexture,
    displacementMap: doorHeightTexture,
    displacementScale: 0.1
})
doorMaterial.normalScale.set(0.2, 0.2)
// End Door Material

// ============ Objects ============

// Ground Plane
const ground = new THREE.Mesh(
    new THREE.PlaneBufferGeometry(20, 20),
    grassMaterial
)
ground.position.y = 0
ground.rotation.x = - Math.PI * 0.5
ground.geometry.setAttribute('uv2', new THREE.Float32BufferAttribute(ground.geometry.attributes.uv.array, 2))
scene.add(ground)

// House
const house = new THREE.Group()
scene.add(house)

// Walls
const walls = new THREE.Mesh(
    new THREE.BoxBufferGeometry(4, 2.5, 4),
    wallMaterial
)
walls.geometry.setAttribute('uv2', new THREE.Float32BufferAttribute(walls.geometry.attributes.uv.array, 2))
walls.position.y = 2.5 * 0.5
house.add(walls)

// Door
const door = new THREE.Mesh(
    new THREE.PlaneBufferGeometry(2.2, 2.2, 100, 100),
    doorMaterial
)
door.geometry.setAttribute('uv2', new THREE.Float32BufferAttribute(door.geometry.attributes.uv.array, 2))
door.position.y = 1
door.position.z = 4 * 0.5 - .02
house.add(door)

// Roof
const roof = new THREE.Mesh(
    new THREE.ConeBufferGeometry(3.5, 2, 4),
    roofMaterial
)
roof.position.y = 3 + 0.5
roof.rotation.y = Math.PI * 0.25
house.add(roof)

// Bushes
const bushGeometry = new THREE.SphereBufferGeometry(1, 16, 16)
const bush1 = new THREE.Mesh(bushGeometry, bushMaterial)
const bush2 = new THREE.Mesh(bushGeometry, bushMaterial)
const bush3 = new THREE.Mesh(bushGeometry, bushMaterial)
const bush4 = new THREE.Mesh(bushGeometry, bushMaterial)
bush1.scale.set(0.5, 0.5, 0.5)
bush1.position.set(0.8, 0.2, 2.2)
bush2.scale.set(0.25, 0.25, 0.25)
bush2.position.set(1.4, 0.1, 2.1)
bush3.scale.set(0.4, 0.4, 0.4)
bush3.position.set(-0.8, 0.1, 2.2)
bush4.scale.set(0.15, 0.15, 0.15)
bush4.position.set(-1, 0.05, 2.6)
house.add(bush1, bush2, bush3, bush4)

//Tombstones
const graves = new THREE.Group()
scene.add(graves)

const graveGeometry = new THREE.BoxBufferGeometry(0.6, 0.8, 0.2)

for(let i = 0; i < 35; i++)
{
    const angle = Math.random() * (Math.PI * 2)
    const radius = 4 + Math.random() * 6
    const x = Math.sin(angle) * radius
    const z = Math.cos(angle) * radius

    const grave = new THREE.Mesh(graveGeometry, graveMaterial)
    grave.position.set(x, 0.3, z)
    grave.rotation.z = (Math.random() - 0.5) * 0.4
    grave.rotation.y = (Math.random() - 0.5) * 0.3
    grave.castShadow = true
    grave.receiveShadow = true
    graves.add(grave)
}

// Axes helper
const axesHelper = new THREE.AxesHelper()
scene.add(axesHelper)
axesHelper.visible = false

// ============ Lights ============
// Ambient Light
//const ambientLight = new THREE.AmbientLight('#b9d5ff', 0.087)
//ambientLight.color = new THREE.Color(parameters.ambientLightColour)
//gui.add(ambientLight, 'intensity').min(0).max(1).step(0.001).name('Ambient Light Intensity')
//scene.add(ambientLight)

// Directional Light
const moonLight = new THREE.DirectionalLight('#b9d5ff', 0.154)
moonLight.position.set(4.68, 3, 3.97)
gui.add(moonLight, 'intensity').min(0).max(1).step(0.001).name('Moonlight Intensity')
gui.add(moonLight.position, 'x').min(-5).max(5).step(0.001).name('Moonlight X Pos')
gui.add(moonLight.position, 'y').min(-5).max(5).step(0.001).name('Moonlight Y Pos')
gui.add(moonLight.position, 'z').min(-5).max(5).step(0.001).name('Moonlight Z Pos')
scene.add(moonLight)

// Door light
const doorLight = new THREE.PointLight('#ff7d46', 0.8, 5)
doorLight.position.set(0, 2.2, 2.7)
house.add(doorLight)

// Ghosts
const ghost1 = new THREE.PointLight('#041f71', 0.25, 3)
gui.add(ghost1, 'intensity').min(0).max(1).step(0.001).name('Ghost 1 Intensity')
gui .addColor(new ColorGUIHelper(ghost1, 'color'), 'value').name('Ghost 1 Colour')
const ghost2 = new THREE.PointLight('#850000', 0.25, 3)
gui.add(ghost2, 'intensity').min(0).max(1).step(0.001).name('Ghost 1 Intensity')
gui .addColor(new ColorGUIHelper(ghost2, 'color'), 'value').name('Ghost 2 Colour')
const ghost3 = new THREE.PointLight('#088000', 0.25, 3)
gui.add(ghost3, 'intensity').min(0).max(1).step(0.001).name('Ghost 1 Intensity')
gui .addColor(new ColorGUIHelper(ghost3, 'color'), 'value').name('Ghost 3 Colour')
scene.add(ghost1, ghost2, ghost3)

// Debug Gui
gui .add(axesHelper,'visible').name('Axes Helper')
//gui .addColor(new ColorGUIHelper(grassMaterial, 'color'), 'value').name('Grass Colour')
gui .add(parameters, 'metalness').min(0).max(1).step(0.0001).name('Metalicity') //standard shader
    .onChange(() => 
    {
        metalMaterial.metalness = parameters.metalness
    })

gui .add(parameters, 'roughness').min(0).max(1).step(0.0001).name('Roughness') //standard shader
    .onChange(() => 
    {
        metalMaterial.roughness = parameters.roughness
    })

gui .add(parameters, 'fov').min(1).max(170).step(1).name('Camera FOV') // Camera FOV
    .onChange(() =>
    {
        camera.fov = parameters.fov
        camera.updateProjectionMatrix()
    })

// Sizes
const sizes = {
    width: window.innerWidth,
    height: window.innerHeight
}
const aspectRatio = sizes.width / sizes.height

window.addEventListener('resize', () => 
{
    // Update sizes
    sizes.width = window.innerWidth
    sizes.height = window.innerHeight

    // Update camera
    camera.aspect = sizes.width / sizes.height
    camera.updateProjectionMatrix()
    
    // Update renderer
    renderer.setSize(sizes.width, sizes.height)
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
})

// Toggle Fullscreen on double click
window.addEventListener('dblclick', () =>
{
    const fullscreenElement = document.fullscreenElement || document.webkitFullscreenElement
    if(!fullscreenElement)
    {
        if(canvas.requestFullscreen){
            canvas.requestFullscreen()
        } else if(canvas.webkitRequestFullscreen) {
            canvas.webkitRequestFullscreen()
        }
    } else {
        if(document.exitFullscreen){
            document.exitFullscreen()
        } else if(document.webkitExitFullscreen){
            document.webkitExitFullscreen()
        }
    }
})

// Camera
const camera = new THREE.PerspectiveCamera(parameters.fov, aspectRatio, 0.1, 100)
scene.add(camera)
camera.position.y = 2
camera.position.x = 3
camera.position.z = 9
camera.lookAt(house)

// Renderer
const renderer = new THREE.WebGLRenderer({
    canvas: canvas
})
renderer.setSize(sizes.width, sizes.height)
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
renderer.setClearColor('#262837')

// Shadows
renderer.shadowMap.enabled = true
renderer.shadowMap.type = THREE.PCFSoftShadowMap

moonLight.castShadow = true
moonLight.shadow.mapSize.width = 256
moonLight.shadow.mapSize.height = 256
moonLight.shadow.camera.far = 7

doorLight.castShadow = true
doorLight.shadow.mapSize.width = 256
doorLight.shadow.mapSize.height = 256
doorLight.shadow.camera.far = 7

ghost1.castShadow = true
ghost1.shadow.mapSize.width = 256
ghost1.shadow.mapSize.height = 256
ghost1.shadow.camera.far = 7

ghost2.castShadow = true
ghost2.shadow.mapSize.width = 256
ghost2.shadow.mapSize.height = 256
ghost2.shadow.camera.far = 7

ghost3.castShadow = true
ghost3.shadow.mapSize.width = 256
ghost3.shadow.mapSize.height = 256
ghost3.shadow.camera.far = 7

bush1.castShadow = true
bush2.castShadow = true
bush3.castShadow = true
bush4.castShadow = true
ground.castShadow = true
walls.castShadow = true

ground.receiveShadow = true
walls.receiveShadow = true
door.receiveShadow = true

//Controls
const controls = new OrbitControls(camera, canvas)
controls.enableDamping = true

// Clock
const clock = new THREE.Clock()

// Candle Flicker
function randomMinMax(min, max) {
    return Math.random() * (max - min) + min;
}
function candleFlicker() {
    doorLight.intensity = randomMinMax(0.5, 0.8)
    setTimeout(candleFlicker, 300)
}
candleFlicker()

//Animations
const tick = () =>
{
    // Clock
    const elapsedTime = clock.getElapsedTime()

    // Animate Ghosts
    const ghost1Angle = elapsedTime * 0.25
    ghost1.position.x = Math.cos(ghost1Angle) * 4
    ghost1.position.z = Math.sin(ghost1Angle) * 4
    ghost1.position.y = Math.sin(elapsedTime * 0.3)

    const ghost2Angle = - elapsedTime * 0.32
    ghost2.position.x = Math.cos(ghost2Angle) * (5 + Math.sin(elapsedTime * 0.032))
    ghost2.position.z = Math.sin(ghost2Angle) * (5 + Math.sin(elapsedTime * 0.05))
    ghost2.position.y = Math.sin(elapsedTime * 0.4) * Math.sin(elapsedTime * .05)

    const ghost3Angle = - elapsedTime * 0.18
    ghost3.position.x = Math.cos(ghost3Angle) * (7 + Math.sin(elapsedTime * 0.032))
    ghost3.position.z = Math.sin(ghost3Angle) * (7 + Math.sin(elapsedTime * 0.05))
    ghost3.position.y = Math.sin(elapsedTime * 0.5) + Math.sin(elapsedTime * 0.2)



    // Update controls
    controls.update()
    
    // Render
    renderer.render(scene, camera)

    // Loop
    window.requestAnimationFrame(tick)
}
tick()